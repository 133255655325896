<template>
  <div class="nursery" v-if="hasPerm('nursery.view_nurserycontract')">
    <page-header title="Contrats Crèche" icon="fa fa-baby-carriage" :links="getLinks()"></page-header>
    <div>
      <b-row>
        <b-col>
          <b-form-group label="Colonnes à afficher" label-for="selectedDisplay">
            <b-select
              v-model="selectedDisplay"
              id="selectedDisplay"
            >
              <b-select-option
                v-for="choice of displays"
                :key="choice.id"
                :value="choice"
              >
                {{ choice.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Contrats à afficher" label-for="selectedContractType">
            <b-select
              v-model="selectedContractType"
              id="selectedContractType"
              @change="loadContracts()"
            >
              <b-select-option
                v-for="choice of contractTypes"
                :key="choice.id"
                :value="choice"
              >
                {{ choice.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="à la date du" label-for="selectedContractType">
            <b-input
              type="date"
              id="dateOn"
              v-model="dateOn"
              :disabled="dateOnDisabled">
            </b-input>
          </b-form-group>
        </b-col>
      </b-row>
      <div ref="printMe" style="margin-top: 10px;">
        <div class="hide-here" v-if="selectedContractType">
          {{ title }}
        </div>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div ref="excelTable" v-if="!isLoading(loadingName)">
          <x-table
            :title="title"
            :columns="columns"
            :items="items"
            class="small"
            show-counter
            verbose-name="contrat"
          >
          </x-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import XTable from '@/components/Controls/Table/Table.vue'
import { BackendMixin } from '@/mixins/backend'
import router from '@/router'
import { makeChoice } from '@/types/base'
import { makeNurseryContract, getDayName } from '@/types/nursery'
import { BackendApi, openDocument } from '@/utils/http'
import { compareNumbers, compareStrings } from '@/utils/sorting'
import { displayHourMin } from '@/utils/time'
import { dateToString } from '@/filters/texts'
import { distinct } from '@/utils/arrays'

export default {
  name: 'nursery-contracts',
  mixins: [BackendMixin],
  components: {
    XTable,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'nursery-contracts',
      contracts: [],
      dateOn: null,
      contractTypes: [
        makeChoice({ id: 1, name: 'Contrats en cours', }),
        makeChoice({ id: 2, name: 'Contrats passés', }),
        makeChoice({ id: 3, name: 'Contrats à venir', }),
        makeChoice({ id: 4, name: 'Contrats à la date du', })
      ],
      selectedContractType: null,
      displays: [
        makeChoice({ id: 1, name: 'Dates de contrat', }),
        makeChoice({ id: 2, name: 'Jour de semaine', })
      ],
      selectedDisplay: null,
      days: [],
    }
  },
  watch: {
    selectedContractType: function() {
      if (this.dateOnDisabled) {
        this.dateOn = null
      } else {
        this.dateOn = moment().format('YYYY-MM-DD')
      }
    },
    dateOn: function() {
      this.loadContracts()
    },
  },
  computed: {
    title() {
      let title = ''
      if (this.selectedContractType) {
        title = this.selectedContractType.name
        title += ' ' + dateToString(this.printDate)
      }
      return title
    },
    printDate() {
      return this.dateOn || moment().format('YYYY-MM-DD')
    },
    dateOnDisabled() {
      return !(this.selectedContractType && this.selectedContractType.id === 4)
    },
    nurseries() {
      return distinct(this.contracts.map(elt => elt.nursery)).sort(
        (elt1, elt2) => compareStrings(elt1.name, elt2.name)
      )
    },
    columns() {
      let columns = [
        {
          name: 'contractType',
          label: 'Type',
        },
        {
          name: 'lastName',
          label: 'Nom',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            if (item.entityId) {
              return router.resolve(
                {
                  name: 'families-detail',
                  params: { entityId: item.entityId, },
                  query: { tab: 'nursery', },
                }
              ).href
            }
          },
        },
        {
          name: 'firstName',
          label: 'Prénom',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            if (item.entityId) {
              return router.resolve(
                {
                  name: 'families-detail',
                  params: { entityId: item.entityId, },
                  query: { individual: item.individual.id, },
                }
              ).href
            }
          },
        },
        {
          name: 'birthDate',
          label: 'Né(e) le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'age',
          label: 'Âge',
        }
      ]
      let subColumns = []
      if (this.selectedDisplay === this.displays[0]) {
        subColumns = [
          {
            name: 'startDate',
            label: 'Début',
            dateFormat: 'DD/MM/YYYY',
          },
          {
            name: 'endDate',
            label: 'Fin',
            dateFormat: 'DD/MM/YYYY',
          },
          {
            name: 'hourlyPrice',
            label: 'Tarif horaire',
            currency: true,
          },
          {
            name: 'lunchPrice',
            label: 'Tarif repas',
            currency: true,
          }
        ]
      }
      if (this.selectedDisplay === this.displays[1]) {
        subColumns = this.days.map(
          day => {
            return {
              name: 'day' + day,
              label: getDayName(day),
            }
          }
        )
      }
      columns = columns.concat(subColumns)
      if (this.nurseries.length > 1) {
        columns = [
          {
            name: 'nursery',
            label: 'Crèche',
          }
        ].concat(columns)
      }
      return columns
    },
    items() {
      return this.contracts.map(elt => this.makeItem(elt))
    },
  },
  mounted() {
    this.selectedContractType = this.contractTypes[0]
    this.selectedDisplay = this.displays[0]
    this.loadContracts()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadContracts() {
      if (this.selectedContractType) {
        let url = '/nursery/api/contracts/'
        if (this.selectedContractType.id === 2) {
          url += '?past=1'
        } else if (this.selectedContractType.id === 3) {
          url += '?future=1'
        } else if (this.selectedContractType.id === 4) {
          url += '?date_on=' + this.dateOn
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.contracts = resp.data.map(elt => makeNurseryContract(elt))
          let days = []
          let map = new Map()
          for (const contract of this.contracts) {
            for (const item of contract.items) {
              if (!map.has(item.day)) {
                days.push(item.day)
                map.set(item.day, item.day)
              }
            }
          }
          this.days = days.sort(compareNumbers)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    makeItem(elt) {
      const newItem = {
        id: elt.id,
        lastName: elt.individual.lastName,
        firstName: elt.individual.firstName,
        entityId: elt.entity.id,
        birthDate: elt.individual.birthDate,
        age: elt.individual.getBabyAge(),
        contractType: elt.getContractTypeLabel(),
        startDate: elt.startDate,
        endDate: elt.endDate,
        hourlyPrice: elt.hourlyPrice,
        lunchPrice: elt.lunchPrice,
        individual: elt.individual,
        entity: elt.entity,
        nursery: elt.nursery.getLabel(),
      }
      for (const day of this.days) {
        newItem['day' + day] = ''
      }
      for (const sub of elt.items) {
        if (newItem['day' + sub.day]) {
          newItem['day' + sub.day] += ' '
        }
        newItem['day' + sub.day] += displayHourMin(sub.arrivalAt) + '-' + displayHourMin(sub.leavingAt)
      }
      return newItem
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'contrats-creche'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'contrats-creche'
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style lang="less">
  .youth-home-days {
    margin-top: 20px;
    padding: 10px;
  }
  .date-header {
    margin-bottom: 5px;
  }
  .daily-workshops {
    margin-top: 30px;
  }
  .nav-date a {
    margin: 5px 2px;
  }
</style>
